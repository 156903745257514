<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form
      @submit.stop.prevent="handleSubmit(onSubmit)"
      @reset.prevent="resetForm"
      autocomplete="off"
    >
      <b-row>
        <b-col cols="12" md="6" v-show="diller.length != 1">
          <b-form-group label="Dil">
            <v-select
              v-model="defaultDil"
              :options="diller"
              :reduce="(diller) => diller.lang"
              label="title"
              :clearable="false"
              :disabled="!form._id"
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Ana Kategori">
            <v-select
              v-model="form.bolge_k_no"
              :options="bolgeler"
              :reduce="(bolge) => bolge.k_no"
              label="baslik"
              :clearable="true"
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12 pt-3" md="6">
          <validation-provider
            name="Başlık"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group label="Başlık">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="baslik"
                v-model="form.icerik[defaultDil].baslik"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
                @input="handlerBaslikChange"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12 pt-3" md="6" v-if="form.bolge_k_no">
          <b-form-group label="Teslimat Ücreti">
            <b-form-input
              size="lg"
              type="number"
              class="rounded-0"
              ref="teslimat_ucreti"
              v-model="form.teslimat_ucreti"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <div class="d-flex justify-content-between align-items-center py-3">
            <label for="statu" class="h6 pt-2">
              Baslik Tüm Dillerde Aynı Olsun
            </label>
            <b-form-checkbox
              :checked="form.baslik_ayni"
              v-model="form.baslik_ayni"
              switch
              size="lg"
              class="pl-0 pr-2"
            />
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="d-flex justify-content-between align-items-center py-3">
            <label for="statu" class="h6">Kullanım Durumu</label>
            <b-form-checkbox
              :checked="form.statu"
              v-model="form.statu"
              switch
              size="lg"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button
              squared
              block
              type="submit"
              size="lg"
              variant="primary"
              class="mr-2"
            >
              <i class="fad fa-save pr-2"></i>
              <span class="align-middle">{{
                form.k_no == null ? "EKLE" : "GÜNCELLE"
              }}</span>
            </b-button>
            <b-button squared size="lg" variant="danger">
              <i class="fad fa-trash"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from "vue-select";
import {
  defineComponent,
  ref,
  computed,
  toRefs,
  watch,
} from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import slugify from "slugify";
import { langs } from "@/libs/languages";
export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    bolgeler: {
      type: Array,
      required: true,
    },
    updateData: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    const { updateData } = toRefs(props);

    expo.slug_duzenle = ref(false);
    expo.AnasayfaStatu = ref([
      {
        value: false,
        title: "Hayır",
      },
      {
        value: true,
        title: "Evet",
      },
    ]);

    expo.urunGrubu = ref([
      {
        value: "villa",
        title: "Villa",
      },
      {
        value: "yat",
        title: "Yat",
      },
      {
        value: "tur",
        title: "Tur",
      },
      {
        value: "transfer",
        title: "Transfer",
      },
      {
        value: "arac_kira",
        title: "Araç Kira",
      },
      {
        value: "saglik_hizmeti",
        title: "Sağlık Hizmeti",
      },
      {
        value: "organizasyon",
        title: "Organizasyon",
      },
    ]);

    expo.form = ref({
      _id: null,
      k_no: null,
      bolge_k_no: null,
      baslik_ayni: true,
      icerik: {},
      gorsel: null,
      icon: "map-marker-alt",
      urun_grubu: [],
      vitrin: false,
      statu: true,
    });

    expo.handlerBaslikChange = (event) => {
      const slug = slugify(event, { lower: true });
      if (expo.form.value.k_no && expo.slug_duzenle.value)
        expo.form.value.icerik[expo.defaultDil.value].slug = slug;

      if (expo.form.value.baslik_ayni) handlerBaslikAyni();
    };

    const handlerBaslikAyni = () => {
      langs.forEach((dil) => {
        if (expo.defaultDil.value != dil.lang) {
          expo.form.value.icerik[dil.lang].baslik =
            expo.form.value.icerik[expo.defaultDil.value].baslik;
        }
      });
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit("show", true);
      store
        .dispatch("bolgeGuncelle", expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success("Güncelleme Başarılı", { position: "bottom-left" });
            context.emit("show", false);
            context.emit("updateRefresh", res.data.data);
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: "bottom-left" });
          }
        });
    };

    watch(
      updateData,
      (val) => {
        if (Object.keys(val).length) {
          expo.form.value = { ...val };
          if (expo.form.value.icerik[expo.defaultDil.value].baslik == "")
            expo.slug_duzenle.value = true;
        } else {
          expo.resetForm();
        }
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
